import { Avatar, Flex, Heading, View } from "@adobe/react-spectrum";
import { Text } from "@react-spectrum/text";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeConfig";
import "./style.css";

const CallDetails = () => {
  const { call, agent } = useSelector((state: RootState) => state);

  let callCustomerAttributes = call?.customer?.attributes || {};
  let agentBusinessAttributes = agent?.businessAttributes || [];
  let notesPresent = false;
  let notesPropertySpelling = "";

  // 1. use filter to compare agent attributes with call attributes. Get matching attributes as output.
  let matchingAttributes = agentBusinessAttributes.filter(function (
    entry: any
  ) {
    if (
      entry.attributeName.toLowerCase() === "notes" &&
      callCustomerAttributes.hasOwnProperty(entry.attributeName)
      // && callCustomerAttributes[entry.attributeName]
    ) {
      notesPresent = true;
      notesPropertySpelling = entry.attributeName;
      return false;
    }

    return callCustomerAttributes.hasOwnProperty(entry.attributeName);
  });

  // 2. Use matching attributes array to create final array which has HTML to show details.
  let finalArray = [];
  for (let i = 0; i < matchingAttributes?.length; i++) {
    const attributeName = matchingAttributes[i]?.attributeName;

    finalArray.push(
      <View key={`attribute-${i}`} marginTop={23}>
        <Flex direction="row" justifyContent="space-between">
          <Flex direction="column">
            <Text UNSAFE_className="call-detail-title" width={160}>
              {displayInProperFormat(attributeName)}
            </Text>
            <Text UNSAFE_className="call-detail-description" width={160}>
              {callCustomerAttributes[attributeName]}
            </Text>
          </Flex>
          {matchingAttributes[i + 1]?.attributeName && (
            <Flex direction="column">
              <Text UNSAFE_className="call-detail-title" width={160}>
                {displayInProperFormat(
                  matchingAttributes[i + 1]?.attributeName
                )}
              </Text>
              <Text UNSAFE_className="call-detail-description" width={160}>
                {
                  callCustomerAttributes[
                    matchingAttributes[i + 1]?.attributeName
                  ]
                }
              </Text>
            </Flex>
          )}
        </Flex>
      </View>
    );

    // Increment i if you are skipping the next attribute.
    if (matchingAttributes[i + 1]) {
      i++; // Skip the next index since it has already been processed
    }
  }

  // 3. Make Notes as separate row.
  if (notesPresent) {
    finalArray.push(
      <Flex direction="column">
        <Text UNSAFE_className="call-detail-title">Transfer Notes</Text>
        <Text UNSAFE_className="call-detail-description">
          {" "}
          {callCustomerAttributes[notesPropertySpelling]}
        </Text>
      </Flex>
    );
  }

  /**
   * for displaying label in "Label Text" format.
   *
   */

  function displayInProperFormat(inputString: string) {
    if (inputString) {
      // replace all _ and - with space
      inputString = inputString.replace(/_/g, " ").replace(/-/g, " ").trim();
      // split with space and convert to desired output
      let inputStringArray = inputString.split(" ");
      inputStringArray = inputStringArray.map(function (nameEntry) {
        return (
          nameEntry.charAt(0).toUpperCase() + nameEntry.slice(1).toLowerCase()
        );
      });
      // join the array with space.
      return inputStringArray.join(" ");
    } else {
      return "";
    }
  }

  return (
    <>
      <View
        borderColor="gray-200"
        borderWidth="thin"
        flexGrow={1}
        backgroundColor={"gray-100"}
        height={"100%"}
      >
        <View
          padding="10px"
          borderBottomWidth="thin"
          borderBottomColor="gray-200"
        >
          <Flex direction="row" alignItems="center">
            <View>
              <Avatar
                src="https://i.imgur.com/kJOwAdv.png"
                alt="avatar with custom size"
                size={40}
              />
            </View>
            <View marginX={12}>
              <Heading level={3} margin={0}>
                <Text>{call?.customer?.displayName}</Text>
              </Heading>
              <Text>
                {call.customer?.phoneNumber ||
                  call.customer?.displayName ||
                  "-"}
              </Text>
            </View>
          </Flex>
        </View>
        <View padding={24}>
          <View>
            <Flex direction="column">
              <Text UNSAFE_className="call-detail-title">Org ID</Text>
              <Text UNSAFE_className="call-detail-description">{call?.id}</Text>
            </Flex>
          </View>
          <Flex direction={"row"} alignItems={"center"} width={'100%'}>
            <Heading level={3} width={'16%'}>
              <Text UNSAFE_className="call-details-heading">Call Details</Text>
            </Heading>
            <View UNSAFE_className="call-detail-divider"></View>
          </Flex>
          <View>{finalArray}</View>
        </View>
      </View>
    </>
  );
};

export default CallDetails;
