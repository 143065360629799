import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Polling from "../../utils/polling";
import Emitter from "../../emitter";
import {
  AGENT_STATUS_LIST,
  API_AGENT_CHANGE,
  API_CHANGE_AGENT_STATE,
} from "../../constants/constants";
import storeRTK from "../../store/storeRTK";
import { setLoader } from "../../actions/common";
import { Flex, Item, Key, Picker, Text } from "@adobe/react-spectrum";

/**
 * Map Store State to Component Props
 * @param {*} state
 */
function mapStateToProps(state: any) {
  return {
    call: state.call,
    agent: state.agent,
    loggedIn: state.session.loggedIn,
    history: state.history,
    callback: state.callback,
    session: state.session,
    webrtc: state.webrtc,
  };
}
let stateModifiedDate: number;
const AgentStatusChange: React.FC = (props: any) => {
  const [stateChangeTimerDuration, setStateChangeTimerDuration] = useState(0);
  const [stateChangeTimerEnabled, setStateChangeTimerEnabled] = useState(false);
  //   const [currentState, setCurrentState] = useState(
  //     new Set([props.agent?.state?.id])
  //   );
  const [currentState, setCurrentState] = useState<Key>(props.agent?.state?.id);

  const canStateTimerbeEnabled = true;

  useEffect(() => {
    stateModifiedDate = props.agent.stateModifiedDate;
  }, [props.agent.stateModifiedDate]);
  const stateChangeTimer = (continuePoll: any, pollingInstance: any) => {
    if (props.call && props.agent && !canStateTimerbeEnabled) {
      pollingInstance.stop();
      setStateChangeTimerEnabled(false);
    }

    setStateChangeTimerDuration(
      Math.max(0, new Date().getTime() - (stateModifiedDate as number))
    );
    continuePoll();
  };

  const polling = new Polling(stateChangeTimer, 1000);

  useEffect(() => {
    setCurrentState(props.agent.state?.id);
  }, [props.agent?.state?.id]);

  useEffect(() => {
    startChangeStateTimer();
    Emitter.on(API_CHANGE_AGENT_STATE, stateUpdated);
    return () => {
      polling.stop();
      Emitter.removeListener(API_CHANGE_AGENT_STATE, stateUpdated);
    };
  }, []);

  const stateUpdated = () => {
    startChangeStateTimer();
    props.onSubmit();
  };

  const startChangeStateTimer = () => {
    if (props.loggedIn && !polling.hasStarted() && canStateTimerbeEnabled) {
      setStateChangeTimerEnabled(true);
      polling.start();
    }
  };

  const getTimerString = () => {
    let secs = 0;
    let mins = 0;
    let hrs = 0;
    if (stateChangeTimerEnabled) {
      secs = Math.floor(stateChangeTimerDuration / 1000);
      if (secs >= 60) {
        mins = Math.floor(secs / 60);
        secs = secs % 60;
      }
      if (mins >= 60) {
        hrs = Math.floor(mins / 60);
        mins = mins % 60;
      }
    }

    return `${hrs.toString().padStart(2, 0)}:${mins
      .toString()
      .padStart(2, 0)}:${secs.toString().padStart(2, 0)}`;
  };

  const updateState = (state: Key) => {
    // if value is there in clicked agent state. Since clicking on same agent state returns {}
    // if ([...state][0]) {
    //   // issue occurs if agent state before ACW is clicked again. acwState has previous agentStatus.
    //   if (props?.agent?.acwState?.id) {
    //     //get latest call and callback and compare their end times.
    //     let latestCall = props?.history?.conversations[0];
    //     let latestCallback = props?.history?.callback[0];

    //     let latestCallEndTime = latestCall?.self?.endTime
    //       ? latestCall.self?.endTime
    //       : "0";
    //     let latestCallbackEndTime = latestCallback?.self?.endTime
    //       ? latestCallback.self?.endTime
    //       : "0";
    //     let participantId = "";
    //     let conversationId = "";

    //     if (
    //       new Date(latestCallEndTime).getTime() -
    //         new Date(latestCallbackEndTime).getTime() >
    //       0
    //     ) {
    //       participantId = latestCall.self.id;
    //       conversationId = latestCall.id;
    //     } else {
    //       participantId = latestCallback.self.id;
    //       conversationId = latestCallback.id;
    //     }

    //     // if values are there for participant id and conversation id
    //     if (participantId && conversationId) {
    //       // wrap up the callback/call.
    //       // sendMessage({
    //       //   body: {
    //       //     wrapupCode: DEFAULT_WRAPUP_CODE,
    //       //     participantId: participantId,
    //       //     conversationId: conversationId,
    //       //   },
    //       //   chan: API_CALL_WRAPUP,
    //       //   type: "req",
    //       // });
    //       // sendMessage({
    //       //     body: {
    //       //         wrapupCode: DEFAULT_WRAPUP_CODE,
    //       //         participantId: participantId,
    //       //         conversationId: conversationId,
    //       //     },
    //       //     chan: API_CALL_WRAPUP,
    //       //     type: 'req',
    //       // });
    //     }
    //   }
    // }
    if (currentState !== state) {
      setCurrentState(state);
      Emitter.emit(API_AGENT_CHANGE, { agentState: state });
    } else {
      console.log(
        `Agent status is already set to ${props.agent.state.displayName}`
      );
      storeRTK.dispatch(setLoader(false));
    }
    // callback();
  };

  return (
    <Flex justifyContent={"space-between"}>
      <Picker
        UNSAFE_className={"agentStatusChangePicker"}
        label={"Status"}
        labelPosition="side"
        items={props.agent.states.allowedStates
          ?.filter((s: any) =>
            [AGENT_STATUS_LIST.ON_QUEUE, AGENT_STATUS_LIST.AVAILABLE].includes(
              s.displayName
            )
          )
          .concat(
            props.agent.states.allowedStates
              .filter(
                (s: any) =>
                  ![
                    AGENT_STATUS_LIST.ON_QUEUE,
                    AGENT_STATUS_LIST.AVAILABLE,
                  ].includes(s.displayName)
              )
              .sort((a: any, b: any) =>
                a.displayName.localeCompare(b.displayName)
              )
          )}
        onSelectionChange={(selected) => {
          updateState(selected);
        }}
        selectedKey={currentState}
      >
        {(item: any) => (
          <Item key={item.displayName}>
            <Text UNSAFE_style={{ fontSize: "0.75rem" }}>
              {item.displayName === "Available"
                ? "Not ready"
                : item.displayName}
            </Text>
          </Item>
        )}
      </Picker>
      <Text marginStart={"size-100"} marginBottom={"7px"} alignSelf={"center"} UNSAFE_className="status-timer">
        {stateChangeTimerEnabled && (
          <Text UNSAFE_style={{ color: "#000" }}>{getTimerString()}</Text>
        )}
      </Text>
    </Flex>
  );
};

export default connect(mapStateToProps)(AgentStatusChange);
