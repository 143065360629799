import React, { useEffect, useState } from "react";
import {
  ActionGroup,
  Flex,
  Heading,
  Item,
  View,
  Selection,
  Text,
  TooltipTrigger,
  Tooltip,
  ActionButton,
} from "@adobe/react-spectrum";
import "./style.css";
import { getSupervisorAgentList } from "../../api/session-api";
import SupervisorSidebar from "./SupervisorSidebar";
import SupervisorCallDetails from "./SupervisorCallDetails";
import { SUPERVISOR_BUTTONS } from "../../constants/constants";
import { AgentStatusDetail } from "../../types/agent.type";
import { RefreshIcon, TimerIcon } from "../common/Icons";

interface Tabs {
  id: number;
  name: string;
}

const Supervisor: React.FC = () => {
  const [agentList, setAgentList] = useState<any>([]);
  const [selectedAgent, setSelectedAgent] = useState<string>("");
  const [filteredAgentList, setFilteredAgentList] = useState<any>(agentList);
  const [selectedState, setSelectedState] = useState<Selection>(
    new Set(["All"])
  );
  const [isFetching, setIsFetching] = useState(true);
  const [statusRefreshTimer, setStatusRefreshTimer] = useState(10);

  // Fetch agent list
  const fetchAgentList = async () => {
    try {
      setStatusRefreshTimer(10);
      const response = await getSupervisorAgentList();
      if (response.length === 0) return;
      if (response?.agent_status && response?.agent_status.length === 0) {
        setIsFetching(false);
        return;
      }
      setAgentList(response?.agent_status);
      setFilteredAgentList(response?.agent_status);
      setIsFetching(false);
      if (selectedAgent === "" && response)
        setSelectedAgent(response?.agent_status[0]?.agent_ldap);
    } catch (error) {
      setIsFetching(false);
      console.error("Failed to fetch agent list:", error);
    }
  };

  useEffect(() => {
    fetchAgentList();
  }, []);

  // Handle status refresh timer countdown
  useEffect(() => {
    if (statusRefreshTimer <= 0) {
      fetchAgentList();
      setStatusRefreshTimer(10);
    }

    const timer = setInterval(() => {
      setStatusRefreshTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [statusRefreshTimer]);

  // Filter agents based on selected status
  const mapFilteredAgentList = (agentStatus: string | undefined) => {
    let filteredAgents = [];

    if (agentStatus && agentList && agentList.length > 0) {
      switch (agentStatus) {
        case "On queue":
          filteredAgents = agentList.filter((agent: AgentStatusDetail) => {
            return (
              agent.agent_current_status_name === "On Queue" &&
              agent.agent_current_contacts.length === 0
            );
          });
          break;
        case "Log-off":
          filteredAgents = agentList.filter((agent: AgentStatusDetail) => {
            return (
              agent.agent_current_status_name === "Offline" &&
              agent.agent_current_contacts.length === 0
            );
          });
          break;
        case "On call":
          filteredAgents = agentList.filter((agent: AgentStatusDetail) => {
            return agent.agent_current_contacts.length > 0;
          });
          break;
        case "Not ready":
          filteredAgents = agentList.filter((agent: AgentStatusDetail) => {
            return (
              agent.agent_current_status_name !== "On Queue" &&
              agent.agent_current_status_name !== "Offline" &&
              agent.agent_current_contacts.length === 0
            );
          });
          break;
        case "All":
          filteredAgents = agentList;
          break;
      }
      setFilteredAgentList(filteredAgents);
    }
  };

  // Handle selection change
  useEffect(() => {
    if (Array.from(selectedState)?.length > 0) {
      const value = Array.from(selectedState)[0];
      let selectedFilter = SUPERVISOR_BUTTONS.find(
        (button: Tabs) => button.name === value
      );
      mapFilteredAgentList(selectedFilter?.name);
    }
  }, [selectedState, agentList]);

  return (
    <Flex gap={"40px"} height={"100%"}>
      <View width={"100%"}>
        <Flex direction={"column"} height={"100%"}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <View paddingStart={16}>
              <Flex gap="100px" alignItems={"center"}>
                <Text UNSAFE_className="call-monitor-heading">
                  Realtime status
                </Text>
                <View UNSAFE_style={{ cursor: "pointer" }}>
                  <Flex alignItems={"center"} marginTop={4}>
                    <View width={20} UNSAFE_className="call-monitor-time-icon">
                      <TimerIcon />
                    </View>
                    <Flex alignItems={"center"}>
                      <View>
                        <Text
                          marginX={5}
                          UNSAFE_className="supervisor-refresh-timer"
                        >
                          Refresh in
                        </Text>
                      </View>
                      <View width={15}>
                        <Text UNSAFE_className="supervisor-refresh-timer">
                          {statusRefreshTimer}s
                        </Text>
                      </View>
                    </Flex>
                    <View marginX={8} marginTop={4}>
                      <TooltipTrigger delay={0}>
                        <ActionButton
                          UNSAFE_className="supervisor-refresh-button"
                          aria-label="Refresh"
                          onPress={fetchAgentList}
                          UNSAFE_style={{ cursor: "pointer" }}
                        >
                          <RefreshIcon color={"#0265DC"} />
                        </ActionButton>
                        <Tooltip>Refresh now</Tooltip>
                      </TooltipTrigger>
                    </View>
                  </Flex>
                </View>
              </Flex>
            </View>
            <View paddingX={32} backgroundColor="static-white">
              <Flex direction={"row"} UNSAFE_className="call-monitor-btn">
                <ActionGroup
                  selectionMode="single"
                  selectedKeys={selectedState}
                  onSelectionChange={setSelectedState}

                >
                  {SUPERVISOR_BUTTONS.map((button: Tabs) => (
                    <Item key={button.name}>
                      <Text UNSAFE_className="call-monitor-action-btn">{button.name}</Text>
                    </Item>
                  ))}
                </ActionGroup>
              </Flex>
            </View>
          </Flex>
          <Flex direction={"row"} alignItems={"start"} height={"100%"}>
            <SupervisorSidebar
              agentList={filteredAgentList}
              selectedAgent={selectedAgent}
              setSelectedAgent={setSelectedAgent}
              isFetching={isFetching}
            />
            <SupervisorCallDetails
              agentList={agentList}
              selectedAgent={selectedAgent}
            />
          </Flex>
        </Flex>
      </View>
    </Flex>
  );
};

export default Supervisor;
