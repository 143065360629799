import { useState, useEffect, useMemo, useCallback, memo } from "react";
import {
  AlertDialog,
  Content,
  DialogContainer,
  Text,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import Emitter from "../../../emitter";
import {
  API_CALL_ANSWER,
  API_CALL_REJECT,
  CALL_STATES,
} from "../../../constants/constants";
import { RootState } from "../../../store/storeConfig";
import "../style.css"

const CallAcceptDialog = () => {
  const [isOpen, setOpen] = useState(true);
  const indCall = useSelector((state: RootState) => state.call);

  useEffect(() => {
    if (indCall && indCall?.id && indCall?.self?.direction !== "OUTBOUND") {
      setOpen(true);
    }
  }, [indCall?.id]);

  const rejectCallHandler = useCallback(() => {
    Emitter.emit(API_CALL_REJECT, {
      body: {
        conversationId: indCall.self?.id,
      },
    });
  }, [indCall]);

  const pickupCallHandler = useCallback(() => {
    Emitter.emit(API_CALL_ANSWER, {
      body: {
        conversationId: indCall.self?.id,
      },
    });
  }, [indCall]);

  const handleModal = useCallback(
    (action: string) => {
      action === "cancel" ? rejectCallHandler() : pickupCallHandler();
      setOpen(false);
    },
    [rejectCallHandler, pickupCallHandler]
  );

  const dialog = useMemo(() => {
    return (
      <DialogContainer
        onDismiss={() => {
          setOpen(false);
        }}
        isDismissable={false}
       
      >
         {isOpen && indCall?.self?.state === CALL_STATES.RINGING && (
          <AlertDialog
            title="New incoming call"
            variant="confirmation"
            primaryActionLabel="Accept"
            cancelLabel="Reject"
            onCancel={() => handleModal("cancel")}
            onPrimaryAction={() => handleModal("accept")}
            UNSAFE_className="call-accept-dialog"
            width={"25rem"}
            height={"15rem"}
          >
            <Content>
              <Text UNSAFE_className="call-accept-dialog-name">{indCall.customer.displayName}</Text>
            </Content>
          </AlertDialog>
        )}
      </DialogContainer>
    );
  }, [isOpen, indCall, handleModal]);

  return <div>{dialog}</div>;
};

export default memo(CallAcceptDialog);
