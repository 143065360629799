import { Flex, Grid, View } from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { NONE } from "../../constants/constants";
import CaseAi from "../CasePage/CaseAI/CaseAi";
import CallInProgress from "./CallInProgress/CallInProgress";
import CallDetails from "./CallDetails/CallDetails";
import "./style.css";
import CallDialer from "./CallDialer/CallDialer";
import { RootState } from "../../store/storeConfig";
import CallAcceptDialog from "../CallPage/CallAcceptDialog/CallAcceptDialog";

const CallWebcase = () => {
  const call = useSelector((state: RootState) => state.call);

  return (
    <>
      {call.id ? (
        <Flex width={"100%"} height={"100%"}>
          <View
            backgroundColor="static-white"
            width={"30%"}
            flex={1}
            height={"100%"}
          >
            <CallInProgress />
          </View>
          <Flex gap={"20px"} width={"70%"}>
            <View
              backgroundColor="static-white"
              gridArea="call detail"
              flex={2}
              paddingTop={"1rem"}
            >
              <CallDetails />
            </View>
            <View
              borderColor="gray-300"
              borderWidth="thin"
              backgroundColor="static-white"
              borderTopWidth={NONE}
              height={"100%"}
              flex={1}
            >
              <CaseAi caseID="" isDisabled={true} />
            </View>
          </Flex>
        </Flex>
      ) : (
        <Flex direction="row" height="100%">
          <CallDialer />
        </Flex>
      )}
      <CallAcceptDialog />
    </>
  );
};

export default CallWebcase;
