import {
    API_CALL_TRANSFER,
    API_CALL_PATCH_ATTRIBUTE,
    API_AGENT_GROUP_STATUS,
    TRANSFER_NOTES_LENGTH,
    API_CALL_TRANSFER_ENDPOINTS,
} from "../../constants/constants";
import {
    Dialog,
    Content,
    Heading,
    Divider,
    DialogContainer,
    ComboBox,
    Item,
    TextArea,
    Checkbox,
    Button,
    Key,
    View,
} from "@adobe/react-spectrum";
import { sendMessage } from "../../services";
import { debounce } from "../../utils";
import "./style.css";
import Emitter from "../../emitter";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addTransferNoteAttribute } from "../../api/session-api";
export const CALL_TRANSFER_TO_OPTIONS = [
    {id: 1, name: 'Queue'},
    // {id: 2, name: "Person"}
]
/**
 * Mapping the Store State to Component Props
 * @param {*} state
 */
function mapStateToProps(state: any) {
    return {
        call: state.call,
        feature: state.features,
        agent: state.agent,
        webrtc: state.webrtc,
    };
}

const CallTransferDialog = (props: any) => {
    const [transferToKeyId, setTransferToKeyId] = useState<Key|null>(CALL_TRANSFER_TO_OPTIONS[0].id);
    const [transferToValueId, setTransferToValueId] = useState<Key|null>(null);
    const [transferToSelectOptions, setTransferToSelectOptions] = useState({
        Person: [],
        Queue: props.call.allowedTransferEndpoints?.filter(
            (q: any) => q.id && q.name && q.quick_connect_type
        ) || [],
    });
    const [isBlindTransfer, setIsBlindTransfer] = useState(true);
    const [isTransferInProgress, setIsTransferInProgress] = useState(false);
    const [transferNotes, setTransferNotes] = useState(props.call.customer?.attributes?.notes);
    const [loadingState, setLoadingState] = useState(false);
   
    useEffect(() => {
        Emitter.on(API_AGENT_GROUP_STATUS, (data) => {
            setTransferToSelectOptions((prevState) => ({
                Person: data.body.entities.map((q: any) => {
                    return { id: q.id, name: q.name };
                }),
                Queue: prevState.Queue,
            }));
            setLoadingState(false);
        });

        Emitter.on(API_CALL_TRANSFER_ENDPOINTS, (event) => {
            setTransferToSelectOptions((prevState) => ({
                Person: prevState.Person,
                Queue: event.body.transferEndpoints,
            }));
        });

        return () => {
            Emitter.off(API_AGENT_GROUP_STATUS);
            Emitter.off(API_CALL_TRANSFER_ENDPOINTS);
        };
    }, []);

    const blindTransfer = () => {
        setIsTransferInProgress(true);
        if (transferToValueId) {
            let notes = transferNotes || "";
            let transferredFromQueue = props.call?.queue?.displayName;
            transferredFromQueue = transferredFromQueue ? transferredFromQueue + ", " : "";

            if (notes.length < TRANSFER_NOTES_LENGTH - 3) {
                let appendToTransferNotes = `\n~${transferredFromQueue}Transferred By: ${
                    props.agent.email.split("@")[0]
                } on ${new Date().toString().split(" ")[4]}`;

                notes = notes + appendToTransferNotes;

                if (notes.length > TRANSFER_NOTES_LENGTH) {
                    notes = notes.substr(0, TRANSFER_NOTES_LENGTH - 3) + "...";
                }
            }

            let body: any = { notes, contactId: props.call.id };
            if (transferToKeyId === 2) {
                //body.userId = transferToValueId;
            } else {
                body.queueId = transferToValueId;
                body.attributes = {
                    TRANSFER: "true",
                    Transferring_Queue: transferToSelectOptions.Queue.filter(
                        (item: any) => item.id === transferToValueId
                    )[0]?.name,
                };
                body.customerId = props.call.customer.id;
                body.transferEndpoint = transferToSelectOptions.Queue.find(
                    (el: any) => el.id === transferToValueId
                );
            }
            addTransferNoteAttribute(body.contactId, notes);
            setTimeout(
                () =>{
                    body.contactId = props.call.id,
                   Emitter.emit(API_CALL_TRANSFER, {type: 'req', body})
                },
                0
            );
        } else {
            setIsTransferInProgress(false);
        }
    };

    const patchAttribute = debounce(() => {
        sendMessage({
            body: {
                notes: transferNotes || "",
            },
            chan: API_CALL_PATCH_ATTRIBUTE,
            type: "req",
        });
    }, 500);

    const updateNotes = (notes: string) => {
        setTransferNotes(notes);
        patchAttribute();
    };



    const resetTransfer = () => {
        props.onClose()
        setTransferToValueId(null);
        setIsBlindTransfer(true);
        setIsTransferInProgress(false);
    };

    const getTransferToOptions = (transferTypeId: any) => {
        if (transferTypeId === 2 && props.agent.groupId) {
            setLoadingState(true);
            sendMessage({
                body: { groupId: props.agent.groupId },
                chan: API_AGENT_GROUP_STATUS,
                type: "req",
            });
        }
    };

    let secs = Math.floor(props.callDuration / 1000);
    let mins = 0;
    let hrs = 0;
    if (secs >= 60) {
        mins = Math.floor(secs / 60);
        secs = secs % 60;
    }
    if (mins >= 60) {
        hrs = Math.floor(mins / 60);
        mins = mins % 60;
    }

    return (
        <DialogContainer onDismiss={() => props.onClose(false)}>
            {props.isOpen && (
                <Dialog size={"S"} UNSAFE_className="custom-dialog">
                    <Heading level={4}>
                        <span className="statusDialogHeading">
                            <span className="dialogHeading">Transfer To</span>
                            <span
                                className="closeDialogButton"
                                onClick={() => props.onClose(false)}
                            >
                                x
                            </span>
                        </span>
                    </Heading>
                    <Divider marginBottom={"size-0"} />
                    <Content maxWidth={"100vw"} marginTop={"15px"}>
                        <div className={"transferDialogContent"}>
                            <div className={"transferContentHeading"}>
                                <div>
                                    <ComboBox
                                        aria-label={"transferTypeSelect"}
                                        width="110px"
                                        defaultItems={CALL_TRANSFER_TO_OPTIONS}
                                        onSelectionChange={(id) => {
                                            setTransferToKeyId(id);
                                            getTransferToOptions(id);
                                        }}
                                        selectedKey={transferToKeyId}
                                    >
                                        {(item) => <Item>{item?.name}</Item>}
                                    </ComboBox>
                                </div>
                                <div>
                                    <ComboBox
                                        aria-label={"transferValueSelect"}
                                        defaultItems={
                                            transferToSelectOptions[
                                                CALL_TRANSFER_TO_OPTIONS.filter(
                                                    (item) => item.id === transferToKeyId
                                                )[0]?.name as keyof typeof transferToSelectOptions
                                            ]
                                        }
                                        onSelectionChange={(id) => setTransferToValueId(id)}
                                        selectedKey={transferToValueId}
                                        loadingState={loadingState ? 'loading' : 'idle'}
                                    >
                                        {(item: any) => <Item>{item?.name}</Item>}
                                    </ComboBox>
                                </div>
                            </div>
                            <div className="transferContent">
                                <div className={"transferNotesHeading"}>
                                    <span>Add Transfer Notes</span>
                                    <span>
                                        {transferNotes
                                            ? `${TRANSFER_NOTES_LENGTH - transferNotes?.length}/${TRANSFER_NOTES_LENGTH}`
                                            : ""}
                                    </span>
                                </div>
                                <TextArea
                                    aria-label={"transferNotesTextarea"}
                                    minHeight={"250px"}
                                    maxLength={TRANSFER_NOTES_LENGTH}
                                    minWidth={"90vw"}
                                    value={transferNotes}
                                    onChange={updateNotes}
                                    isQuiet
                                />
                            </div>
                        </div>
                    </Content>

                    <View UNSAFE_className={"transferDialogFooter"}>
                        <div>
                            <Checkbox
                                aria-label={"blindTransferCheckbox"}
                                isEmphasized={true}
                                isDisabled={true}
                                isSelected={isBlindTransfer}
                                onChange={(val) => setIsBlindTransfer(val)}
                            >
                                Blind Transfer
                            </Checkbox>
                        </div>
                        <div>
                            <Button
                                variant="negative"
                                isDisabled={isTransferInProgress}
                                onPress={resetTransfer}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="cta"
                                marginStart="15px"
                                isDisabled={!transferToValueId}
                                onPress={blindTransfer}
                            >
                                Continue
                            </Button>
                        </div>
                    </View>
                </Dialog>
            )}
        </DialogContainer>
    );
};

export default connect(mapStateToProps)(CallTransferDialog);
