import { useState, useEffect } from "react";
import {
  Provider,
  defaultTheme,
  Dialog,
  Content,
  Heading,
  Divider,
  Text,
  ButtonGroup,
  Button,
  DialogContainer,
  AlertDialog,
  View,
  ProgressCircle,
} from "@adobe/react-spectrum";
import Dashboard from "../Dashboard";
import Loader from "../Loader/Loader";
import Login from "./Login";
import { connect, useSelector } from "react-redux";
import "./index.css";
import {
  BG_MEDIA_PERM_CHECK,
  BG_ASK_MEDIA_PERM,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  BG_BROADCAST,
  HIDE_MEDIA_PERM_POPUP,
  SESSION_LOGOUT_MESSAGE,
  SESSION_LOGOUT_MESSAGE_CLOSE,
  CCP_ASK_MEDIA_PERMISSION,
  OPEN_MEDIA_PERMISSION_TAB,
} from "../../constants/constants";
import { sendMessage } from "../../services";
import { Alert } from "../common/Icons";
import {
  connectSession,
  registerConnectSessionHandlers,
} from "../../amazon-connect/handlers/session";
import { STATE_LOGIN } from "../../amazon-connect/constants";
import { session } from "../../handlers/session";
import Emitter from "../../emitter";
import storeRTK from "../../store/storeRTK";
import { setLoader } from "../../actions/common";
import registerEventHandlers from "../../handlers";
import { RootState } from "../../store/storeConfig";
import registerApiEvents from "../../api";
import registerEventHandlerControlllers from "../../controller";
import registerConnectApiHandlers from "../../amazon-connect/api";

function mapStateToProps(state: any) {
  return {
    session: state.session,
    loggedIn: state.session.loggedIn,
  };
}

registerEventHandlers();
registerApiEvents();
registerEventHandlerControlllers();
// Listen to connect api events
registerConnectApiHandlers();
registerConnectSessionHandlers();
const LoginPage = (props: any) => {
  const [showPermPopup, setShowPermPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState<any>({});
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const [showCCPPermPopup, setShowCCPPermPopup] = useState(false);
  const ccpSessionState = useSelector(
    (state: RootState) => state.webrtc.ccpSessionState
  );
  const isLoading = useSelector((state: RootState) => state.common.isLoading);

  
  useEffect(() => {
    const localStorageStateData = localStorage.getItem("reduxState");
    if (localStorageStateData !== null) {
      const savedStateData = JSON.parse(localStorageStateData);
      const { agent } = savedStateData || {};
      if (agent && agent.id) {
        connectSession.initiateCcpSession();
        session.onloggedIn({
          type: "notif",
          chan: STATE_LOGIN,
          body: savedStateData.agent,
          success: true,
        });
      }
      localStorage.removeItem("reduxState");
    }
    if (props.session?.isLoginProcessing) {
      storeRTK.dispatch(setLoader(true));
    } else {
      storeRTK.dispatch(setLoader(false));
    }

    const handleMediaPermCheck = (res: any) => {
      if (res.body && !res.body.wasAllowed) {
        setShowPermPopup(true);
      }
    };

    const handleCCPAskMediaPermission = (res: any) => {
      setShowCCPPermPopup(true);
    };

    const handleShowNotification = (body: any) => {
      setShowNotification(true);
      setNotification(body);
    };

    const handleHideNotification = () => {
      setShowNotification(false);
      setNotification({});
    };

    const handleHideMediaPermPopup = () => {
      setShowPermPopup(false);
      setShowCCPPermPopup(false);
    };

    const handleSessionLogoutMessage = () => {
      setShowLogoutDialog(true);
    };

    const handleSessionLogoutMessageClose = () => {
      setShowLogoutDialog(false);
    };

    // Subscribe to events
    Emitter.on(BG_MEDIA_PERM_CHECK, handleMediaPermCheck);
    Emitter.on(CCP_ASK_MEDIA_PERMISSION, handleCCPAskMediaPermission);
    Emitter.on(SHOW_NOTIFICATION, handleShowNotification);
    Emitter.on(HIDE_NOTIFICATION, handleHideNotification);
    Emitter.on(HIDE_MEDIA_PERM_POPUP, handleHideMediaPermPopup);
    Emitter.on(SESSION_LOGOUT_MESSAGE, handleSessionLogoutMessage);
    Emitter.on(SESSION_LOGOUT_MESSAGE_CLOSE, handleSessionLogoutMessageClose);

    return () => {
      // Unsubscribe from events
      Emitter.off(BG_MEDIA_PERM_CHECK, handleMediaPermCheck);
      Emitter.off(CCP_ASK_MEDIA_PERMISSION, handleCCPAskMediaPermission);
      Emitter.off(SHOW_NOTIFICATION, handleShowNotification);
      Emitter.off(HIDE_NOTIFICATION, handleHideNotification);
      Emitter.off(HIDE_MEDIA_PERM_POPUP, handleHideMediaPermPopup);
      Emitter.off(SESSION_LOGOUT_MESSAGE, handleSessionLogoutMessage);
      Emitter.off(
        SESSION_LOGOUT_MESSAGE_CLOSE,
        handleSessionLogoutMessageClose
      );
      // Clean up state
      setShowLogoutDialog(false);
    };
  }, []);

  const openSettingsPage = () => {
    sendMessage({
      type: "req",
      chan: BG_ASK_MEDIA_PERM,
    });
    setShowPermPopup(false);
    sendMessage({
      type: "notif",
      chan: BG_BROADCAST,
      body: {
        type: "notif",
        body: "",
        chan: HIDE_MEDIA_PERM_POPUP,
      },
    });
  };

  const openCCPPermissionTab = () => {
    sendMessage({
      type: "req",
      chan: OPEN_MEDIA_PERMISSION_TAB,
    });
    setShowCCPPermPopup(false);
    sendMessage({
      type: "notif",
      chan: BG_BROADCAST,
      body: {
        type: "notif",
        body: "",
        chan: HIDE_MEDIA_PERM_POPUP,
      },
    });
  };

  const handleOnPress = () => {
    setShowNotification(false);
    sendMessage({
      type: "notif",
      chan: BG_BROADCAST,
      body: {
        type: "notif",
        body: "",
        chan: HIDE_NOTIFICATION,
      },
    });
  };

  const handleOnPressLogoutPopup = () => {
    setShowLogoutDialog(false);
    sendMessage({
      type: "notif",
      chan: BG_BROADCAST,
      body: {
        type: "notif",
        body: "",
        chan: SESSION_LOGOUT_MESSAGE_CLOSE,
      },
    });
  };

  return (
    <Provider theme={defaultTheme} colorScheme="light">
      <View height={"100vh"} width={"100vw"}>
        <View height={'100%'}>
            {/* <ErrorBoundary> */}
            <View height={'100%'}>
              {!props.session.loggedIn && <Login />}
              {props.session.loggedIn && <Dashboard />}
              {
                <View>
                  {isLoading ? (
                    <Loader checkLoading="loading" />
                  ) : ccpSessionState !== 4 && ccpSessionState !== 3 ? (
                    <Loader checkLoading="webrtc" />
                  ) : null}
                </View>
              }
            </View>
            <DialogContainer onDismiss={() => {}}>
              {showPermPopup && (
                <Dialog>
                  <Heading level={4}>
                    <span>
                      <span
                        style={{
                          marginRight: "15px",
                        }}
                      >
                        <Alert />
                      </span>
                      <span>Permission Required</span>
                    </span>
                  </Heading>
                  <Divider />
                  <Content>
                    <Text>
                      This app will not work unless microphone permission is
                      allowed. Click below button to continue{" "}
                    </Text>
                  </Content>
                  <ButtonGroup>
                    <Button
                      variant="cta"
                      onPress={() => {
                        openSettingsPage();
                      }}
                      autoFocus
                    >
                      Allow
                    </Button>
                  </ButtonGroup>
                </Dialog>
              )}
            </DialogContainer>
            <DialogContainer onDismiss={() => {}}>
              {showCCPPermPopup && (
                <Dialog>
                  <Heading level={4}>
                    <span>
                      <span
                        style={{
                          marginRight: "15px",
                        }}
                      >
                        <Alert />
                      </span>
                      <span>Permission Required</span>
                    </span>
                  </Heading>
                  <Divider />
                  <Content>
                    <Text>
                      This app will not work unless microphone permission is
                      allowed. Click below button to continue{" "}
                    </Text>
                  </Content>
                  <ButtonGroup>
                    <Button
                      variant="cta"
                      onPress={() => {
                        openCCPPermissionTab();
                      }}
                      autoFocus
                    >
                      Allow
                    </Button>
                  </ButtonGroup>
                </Dialog>
              )}
            </DialogContainer>
            <DialogContainer onDismiss={() => {}}>
              {showLogoutDialog && (
                <Dialog>
                  <Heading level={4}>
                    <span>
                      <span
                        style={{
                          marginRight: "15px",
                        }}
                      >
                        <Alert />
                      </span>
                      <span>Logged out</span>
                    </span>
                  </Heading>
                  <Divider />
                  <Content>
                    <Text>
                      You were logged out due to network disconnect. Please
                      login again to continue
                    </Text>
                  </Content>
                  <ButtonGroup>
                    <Button
                      variant="cta"
                      onPress={handleOnPressLogoutPopup}
                      autoFocus
                    >
                      ok
                    </Button>
                  </ButtonGroup>
                </Dialog>
              )}
            </DialogContainer>
            {/* </ErrorBoundary> */}
            <DialogContainer onDismiss={() => handleOnPress()}>
              {showNotification && (
                <AlertDialog
                  title={notification.title || "Network error!"}
                  variant={notification.type || "information"}
                  primaryActionLabel="Ok"
                  onPrimaryAction={handleOnPress}
                >
                  {notification.message}
                </AlertDialog>
              )}
            </DialogContainer>
        </View>
      </View>
    </Provider>
  );
};

export default connect(mapStateToProps)(LoginPage);
